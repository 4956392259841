import React, { useEffect } from 'react';
import { KickerChapter3Table, KickerChapter3TableConfigProps } from '@webfox-sc/dataviz-poc';
import { dateFormatterShort, timeFormatter } from '../../utils/date';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useParams } from 'react-router-dom';
import { fetchStreaksChapter } from '../../app/slices/chaptersSlice';
import { getColors } from '../../utils/color';

type StreakType = 'notLost' | 'notWon';

const mapMatchResult = (matchResult: 'won' | 'lost' | 'tie' | 'notPlayed'): 'win' | 'loss' | 'draw' | 'notPlayed' => {
  if (matchResult === 'won') {
    return 'win';
  }
  if (matchResult === 'lost') {
    return 'loss';
  }
  if (matchResult === 'tie') {
    return 'draw';
  }
  return 'notPlayed';
};

const getChapterConfig = (
  chapter: StreaksChapter | undefined,
  type: StreakType
): KickerChapter3TableConfigProps | undefined => {
  if (!chapter?.streaks) {
    return undefined;
  }

  const kickoffTime = new Date(chapter.plannedKickoffTime || '');

  const streaks = chapter.streaks.filter((streak) => streak.type === type);

  const maxStreakIndex = type === 'notLost' ? 0 : streaks.length - 1;
  const maxStreakLength = streaks[maxStreakIndex]?.streak.length || 1;
  const gameDays = Array.from(Array(maxStreakLength)).map(
    (_, index) => index + ((chapter.matchday?.matchDay || 0) - maxStreakLength)
  );

  const rowsPropertyName = type === 'notLost' ? 'winRows' : 'lossRows';

  const [color1, color2] = getColors(chapter.home?.colorSets, chapter.away?.colorSets);

  return {
    date: dateFormatterShort.format(new Date(kickoffTime)),
    time: timeFormatter.format(kickoffTime),
    goals: chapter.hasResults
      ? {
          home: chapter.homeGoals || 0,
          away: chapter.awayGoals || 0,
        }
      : undefined,
    club1: {
      name: chapter.home?.shortName || null,
      logoImageSrc: chapter.home?.teamIconUrl || null,
      logoImageAlt: chapter.home?.threeLetterCode || null,
      color: color1.background,
      textColor: color1.color,
    },
    club2: {
      name: chapter.away?.shortName || null,
      logoImageSrc: chapter.away?.teamIconUrl || null,
      logoImageAlt: chapter.away?.threeLetterCode || null,
      color: color2.background,
      textColor: color2.color,
    },
    gameDays,
    rankStart: type === 'notLost' ? 1 : chapter.streaks.length - streaks.length + 1,
    [rowsPropertyName]: streaks.map((streak) => ({
      teamName: [chapter.home?.id, chapter.away?.id].includes(streak.team.id)
        ? streak.team.shortName
        : streak.team.threeLetterCode,
      backgroundColor:
        chapter.home?.id === streak.team.id
          ? color1.background
          : chapter.away?.id === streak.team.id
          ? color2.background
          : undefined,
      textColor:
        chapter.home?.id === streak.team.id
          ? color1.color
          : chapter.away?.id === streak.team.id
          ? color2.color
          : undefined,
      series: streak.streak.map(mapMatchResult),
    })),
  };
};

interface Chapter3Props {
  streakType: StreakType;
}

const Chapter3: React.FC<Chapter3Props> = ({ streakType }) => {
  const dispatch = useAppDispatch();
  const { matchId } = useParams();
  const chapter = useAppSelector((state) => state.chapters.streaks.entities[matchId || 0]);

  useEffect(() => {
    (async () => {
      if (matchId) {
        await dispatch(fetchStreaksChapter(matchId));
      }
    })();
  }, [dispatch, matchId]);

  const chapterConfig = getChapterConfig(chapter, streakType);

  return <>{chapterConfig && <KickerChapter3Table config={chapterConfig} />}</>;
};

export default Chapter3;
