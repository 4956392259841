import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { KickerChapterFooter, KickerChapterHeader } from '@webfox-sc/dataviz-poc';
import Chapter1 from './Chapter1';
import Chapter2 from './Chapter2';
import Chapter3 from './Chapter3';
import Chapter4 from './Chapter4';
import Chapter5 from './Chapter5';
import { useCurrentMatch } from '../match/useCurrentMatch';
import { getDateFormatted } from '../../utils/date';
import CloseButton from '../atoms/CloseButton';
import { UiDiv, UiFlex, UiImg, UiLink } from '@webfox-sc/core';
import Ad from './Ad';

type Chapter = {
  headline?: string;
  chapterNr?: number;
  isAd?: boolean;
  component: JSX.Element;
};

const chapterLabels = ['1', '2', 'ad1', '3.1', '3.2', '4', '5', 'ad2'] as const;

const chapters: Record<(typeof chapterLabels)[number], Chapter> = {
  '1': { headline: 'Punkte gegen Tabellenplätze', chapterNr: 1, component: <Chapter1 /> },
  '2': { headline: 'Tabelle vor 1 Jahr vs. heute', chapterNr: 2, component: <Chapter2 /> },
  ad1: { isAd: true, component: <Ad /> },
  '3.1': { headline: 'Serien ohne Niederlage', chapterNr: 3, component: <Chapter3 streakType="notLost" /> },
  '3.2': { headline: 'Serien ohne Sieg', chapterNr: 3, component: <Chapter3 streakType="notWon" /> },
  '4': { headline: 'Tordifferenz', chapterNr: 4, component: <Chapter4 /> },
  '5': { headline: 'Punkte aus den letzten 10 direkten Duellen', chapterNr: 5, component: <Chapter5 /> },
  ad2: { isAd: true, component: <Ad /> },
};

const Chapters: React.FC = () => {
  const { matchId, chapterId } = useParams();
  const navigate = useNavigate();

  const { match } = useCurrentMatch(matchId);

  const initialIndex = chapterLabels.findIndex((label) => label === chapterId);

  const [currentChapterIndex, setCurrentChapterIndex] = useState(initialIndex === -1 ? 0 : initialIndex);

  useEffect(() => {
    setCurrentChapterIndex(initialIndex === -1 ? 0 : initialIndex);
  }, [initialIndex]);

  const chapterLabel = chapterLabels[currentChapterIndex];
  const chapter = chapters[chapterLabel];

  const handleOnClickPref = () => {
    if (currentChapterIndex > 0) {
      navigate(`/match/${matchId}/kapitel/${chapterLabels[currentChapterIndex - 1]}`);
    } else {
      navigate('/');
    }
  };

  const handleOnClickNext = () => {
    if (currentChapterIndex < chapterLabels.length - 1) {
      navigate(`/match/${matchId}/kapitel/${chapterLabels[currentChapterIndex + 1]}`);
    } else {
      navigate('/');
    }
  };

  return (
    <UiFlex flexFlow="column" height="860px" maxHeight="100vh">
      <UiDiv position="absolute" right="28px" top="30px">
        <CloseButton onClick={() => navigate('/')} />
      </UiDiv>

      <KickerChapterHeader
        headline={chapter.headline}
        infoText={`${match?.home?.shortName} – ${match?.away?.shortName}: Die Datenvorschau`}
        date={match?.plannedKickoffTime ? getDateFormatted(new Date(match?.plannedKickoffTime)) : null}
        chapterNr={chapter.chapterNr}
        chapterNrTotal={5}
        partnerLogoSrc={chapter.isAd ? undefined : '/img/partner-logo-placeholder.png'}
        partnerLogoAlt="Platzhalter"
      />

      <UiDiv width="100%" overflow="auto" flexGrow="1">
        {chapter.component}
      </UiDiv>

      <UiDiv>
        <KickerChapterFooter
          chapterNr={chapter.isAd ? undefined : chapterLabel}
          chapterNrTotal={chapter.isAd ? undefined : 5}
          onClickPref={handleOnClickPref}
          onClickNext={handleOnClickNext}
        />

        <UiFlex paddingTop={chapter.isAd ? '100px' : '50px'} paddingBottom="10px" justifyContent="center">
          {!chapter.isAd && (
            <UiLink href="https://fortedigital.no/de" target="_blank">
              <UiImg
                width="320px"
                height="50px"
                objectFit="contain"
                display="block"
                maxWidth="100%"
                src="/img/footer-placeholder.png"
              />
            </UiLink>
          )}
        </UiFlex>
      </UiDiv>
    </UiFlex>
  );
};

export default Chapters;
