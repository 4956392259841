import React, { useEffect } from 'react';
import { KickerChapter1Table } from '@webfox-sc/dataviz-poc';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { fetchPointsBeforeChapter } from '../../app/slices/chaptersSlice';
import { useParams } from 'react-router-dom';
import { KickerChapter1TableConfigProps } from '@webfox-sc/dataviz-poc/dist/components-kicker/KickerChapter1Table/KickerChapter1Table';
import { dateFormatterShort, timeFormatter } from '../../utils/date';
import { getColors } from '../../utils/color';

const getPointsString = (points?: number): 'one' | 'three' | 'zero' | 'open' => {
  switch (points) {
    case 0:
      return 'zero';
    case 1:
      return 'one';
    case 3:
      return 'three';
    default:
      return 'open';
  }
};

const getChapterConfig = (chapter?: PointsBeforeChapter): KickerChapter1TableConfigProps | undefined => {
  if (!chapter) {
    return undefined;
  }

  const kickoffTime = new Date(chapter.plannedKickoffTime || '');

  const [color1, color2] = getColors(chapter.home?.colorSets, chapter.away?.colorSets);

  return {
    date: dateFormatterShort.format(new Date(kickoffTime)),
    club1: {
      name: chapter.home?.shortName || null,
      logoImageSrc: chapter.home?.teamIconUrl || null,
      logoImageAlt: chapter.home?.threeLetterCode || null,
      clubTotal: (chapter.pairings || []).reduce(
        (total, pairing) => total + (pairing.home1?.points || 0) + (pairing.home2?.points || 0),
        0
      ),
      color: color1.background,
      textColor: color1.color,
    },
    club2: {
      name: chapter.away?.shortName || null,
      logoImageSrc: chapter.away?.teamIconUrl || null,
      logoImageAlt: chapter.away?.threeLetterCode || null,
      clubTotal: (chapter.pairings || []).reduce(
        (total, pairing) => total + (pairing.away1?.points || 0) + (pairing.away2?.points || 0),
        0
      ),
      color: color2.background,
      textColor: color2.color,
    },
    time: timeFormatter.format(kickoffTime),
    goals: chapter.hasResults
      ? {
          home: chapter.homeGoals || 0,
          away: chapter.awayGoals || 0,
        }
      : undefined,
    rankNumsWithBorderTop: [1, 5, 6, 7, 16, 17],
    rows: (chapter.pairings || []).map((pairing) => ({
      teamName: [chapter.home?.shortName, chapter.away?.shortName].includes(pairing.team.shortName)
        ? pairing.team.shortName
        : pairing.team.threeLetterCode,
      teamPosition:
        chapter.home?.shortName === pairing.team.shortName
          ? 'left'
          : chapter.away?.shortName === pairing.team.shortName
          ? 'right'
          : undefined,
      team1PointsHome:
        chapter.home?.shortName !== pairing.team.shortName ? getPointsString(pairing?.home1?.points) : null,
      team1PointsAway:
        chapter.home?.shortName !== pairing.team.shortName ? getPointsString(pairing?.home2?.points) : null,
      team2PointsHome:
        chapter.away?.shortName !== pairing.team.shortName ? getPointsString(pairing?.away1?.points) : null,
      team2PointsAway:
        chapter.away?.shortName !== pairing.team.shortName ? getPointsString(pairing?.away2?.points) : null,
    })),
  };
};

const Chapter1: React.FC = () => {
  const dispatch = useAppDispatch();
  const { matchId } = useParams();
  const chapter = useAppSelector((state) => state.chapters.pointsBefore.entities[matchId || 0]);

  useEffect(() => {
    (async () => {
      if (matchId) {
        await dispatch(fetchPointsBeforeChapter(matchId));
      }
    })();
  }, [dispatch, matchId]);

  const chapterConfig = getChapterConfig(chapter);

  return <>{chapterConfig && <KickerChapter1Table config={chapterConfig} />}</>;
};

export default Chapter1;
