import React, { useEffect } from 'react';
import {
  KickerChapter5Table,
  KickerChapter5TableConfigProps,
  KickerChapter5TableRowProps,
} from '@webfox-sc/dataviz-poc';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useParams } from 'react-router-dom';
import { fetchPreviousMatchesChapter } from '../../app/slices/chaptersSlice';
import { dateFormatterShort, timeFormatter } from '../../utils/date';
import { getColors } from '../../utils/color';

const tinyDateFormatter = new Intl.DateTimeFormat('de-DE', {
  day: 'numeric',
  month: '2-digit',
  timeZone: 'Europe/Berlin',
});

const getChapterConfig = (chapter?: PreviousMatchesChapter): KickerChapter5TableConfigProps | undefined => {
  if (!chapter?.home || !chapter?.away) {
    return undefined;
  }

  const kickoffTime = new Date(chapter.plannedKickoffTime || '');

  const matchStats = (chapter.matches || []).reduce(
    (result, match) => {
      if (typeof match.homeGoals === 'number' && typeof match.awayGoals === 'number' && match.home && match.away) {
        const homeGoals = match.homeGoals;
        const awayGoals = match.awayGoals;
        const homeMatchResult = homeGoals > awayGoals ? 'won' : homeGoals < awayGoals ? 'lost' : 'tie';
        const awayMatchResult = homeGoals < awayGoals ? 'won' : homeGoals > awayGoals ? 'lost' : 'tie';
        result[match.home.id][homeMatchResult] += 1;
        result[match.away.id][awayMatchResult] += 1;
        result[match.home.id].points += match.homePoints;
        result[match.away.id].points += match.awayPoints;
      }
      return result;
    },
    {
      [chapter.home?.id]: {
        won: 0,
        tie: 0,
        lost: 0,
        points: 0,
      },
      [chapter.away?.id]: {
        won: 0,
        tie: 0,
        lost: 0,
        points: 0,
      },
    }
  );

  const matchesGroupedBySeason = (chapter.matches || []).reduce((result, match) => {
    const season = match.season?.season || '';
    if (!result[season]) {
      result[season] = [];
    }
    result[season].push(match);
    return result;
  }, {} as Record<string, typeof chapter.matches>);

  const orderedSeasons = Object.keys(matchesGroupedBySeason).sort().reverse();
  let rows: KickerChapter5TableRowProps[] = [];

  if (orderedSeasons.length) {
    if (matchesGroupedBySeason[orderedSeasons[0]].length === 1) {
      matchesGroupedBySeason[orderedSeasons[0]].unshift({
        home: matchesGroupedBySeason[orderedSeasons[0]][0].away,
      } as any);
    }
    rows = orderedSeasons.map((season) => {
      const matches = matchesGroupedBySeason[season];
      const gameDate1 =
        matches[0].plannedKickoffTime && !matches[0].hasResults
          ? tinyDateFormatter.format(new Date(matches[0].plannedKickoffTime))
          : undefined;
      const gameDate2 =
        matches[1].plannedKickoffTime && !matches[1].hasResults
          ? tinyDateFormatter.format(new Date(matches[1].plannedKickoffTime))
          : undefined;
      const goalsClub1FirstLeg =
        (chapter.home?.id === matches[0].home?.id ? matches[0].homeGoals : matches[0].awayGoals) ?? undefined;
      const goalsClub2FirstLeg =
        (chapter.away?.id === matches[0].home?.id ? matches[0].homeGoals : matches[0].awayGoals) ?? undefined;
      const goalsClub1Return =
        (chapter.home?.id === matches[1].home?.id ? matches[1].homeGoals : matches[1].awayGoals) ?? undefined;
      const goalsClub2Return =
        (chapter.away?.id === matches[1].home?.id ? matches[1].homeGoals : matches[1].awayGoals) ?? undefined;
      return {
        season,
        place1: matches[0].home?.labels?.location || `in ${matches[0].home?.shortName}`,
        place2: matches[1].home?.labels?.location || `in ${matches[1].home?.shortName}`,
        gameDate1,
        gameDate2,
        goalsClub1FirstLeg,
        goalsClub2FirstLeg,
        goalsClub1Return,
        goalsClub2Return,
      };
    });
  }

  const [color1, color2] = getColors(chapter.home?.colorSets, chapter.away?.colorSets);

  return {
    date: dateFormatterShort.format(new Date(kickoffTime)),
    club1: {
      name: chapter.home?.shortName || null,
      logoImageSrc: chapter.home?.teamIconUrl || null,
      logoImageAlt: chapter.home?.threeLetterCode || null,
      color: color1.background,
      textColor: color1.color,
      goalsScored: matchStats[chapter.home.id].won,
      goalsDrawn: matchStats[chapter.home.id].tie,
      goalsConceded: matchStats[chapter.home.id].lost,
      clubTotal: matchStats[chapter.home.id].points,
    },
    club2: {
      name: chapter.away?.shortName || null,
      logoImageSrc: chapter.away?.teamIconUrl || null,
      logoImageAlt: chapter.away?.threeLetterCode || null,
      color: color2.background,
      textColor: color2.color,
      goalsScored: matchStats[chapter.away.id].won,
      goalsDrawn: matchStats[chapter.away.id].tie,
      goalsConceded: matchStats[chapter.away.id].lost,
      clubTotal: matchStats[chapter.away.id].points,
    },
    time: timeFormatter.format(kickoffTime),
    goals: chapter.hasResults
      ? {
          home: chapter.homeGoals || 0,
          away: chapter.awayGoals || 0,
        }
      : undefined,
    rows,
  };
};

const Chapter5: React.FC = () => {
  const dispatch = useAppDispatch();
  const { matchId } = useParams();
  const chapter = useAppSelector((state) => state.chapters.previousMatches.entities[matchId || 0]);

  useEffect(() => {
    (async () => {
      if (matchId) {
        await dispatch(fetchPreviousMatchesChapter(matchId));
      }
    })();
  }, [dispatch, matchId]);

  const chapterConfig = getChapterConfig(chapter);

  return <>{chapterConfig && <KickerChapter5Table config={chapterConfig} />}</>;
};

export default Chapter5;
