import React from 'react';
import { UiButton } from '@webfox-sc/core';

interface CloseButtonProps {
  onClick?: () => void;
}

const CloseButton: React.FC<CloseButtonProps> = ({ onClick }) => {
  return (
    <UiButton
      width="34px"
      height="34px"
      position="relative"
      borderRadius="50%"
      border="1px solid #707070"
      backgroundColor="white"
      cursor="pointer"
      transform="rotate(45deg)"
      transition="transform .2s ease"
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      csso={{
        '&::before': {
          content: '""',
          position: 'absolute',
          width: '20px',
          height: '3px',
          background: '#707070',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          width: '3px',
          height: '20px',
          background: '#707070',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        },
        '&:hover': {
          transform: 'rotate(135deg)',
        },
      }}
    />
  );
};

export default CloseButton;
