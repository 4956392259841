const fallbackColorSet = { color: '#FFFFFF', background: '#B4B4B4' };

const getColorScheme = (colorSets: ColorSets | undefined): ColorScheme => {
  if (colorSets) {
    if (colorSets.primary?.background?.toLowerCase() === '#ffffff') {
      return colorSets.secondary;
    }
    return colorSets.primary;
  }
  return fallbackColorSet;
};

export const getColors = (colorSets1: ColorSets | undefined, colorSets2: ColorSets | undefined): ColorScheme[] => {
  return [getColorScheme(colorSets1), getColorScheme(colorSets2)];
};
