import React, { useEffect } from 'react';
import { KickerChapter4Table, KickerChapter4TableConfigProps } from '@webfox-sc/dataviz-poc';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useParams } from 'react-router-dom';
import { fetchGoalsBeforeChapter } from '../../app/slices/chaptersSlice';
import { dateFormatterShort, timeFormatter } from '../../utils/date';
import { getColors } from '../../utils/color';

const getChapterConfig = (chapter?: GoalsBeforeChapter): KickerChapter4TableConfigProps | undefined => {
  if (!chapter) {
    return undefined;
  }

  const kickoffTime = new Date(chapter.plannedKickoffTime || '');

  const [color1, color2] = getColors(chapter.home?.colorSets, chapter.away?.colorSets);

  return {
    date: dateFormatterShort.format(new Date(kickoffTime)),
    club1: {
      name: chapter.home?.shortName || null,
      logoImageSrc: chapter.home?.teamIconUrl || null,
      logoImageAlt: chapter.home?.threeLetterCode || null,
      clubTotal: chapter.homeGoals,
      color: color1.background,
      textColor: color1.color,
      goalsScored: chapter.homeGoalsBefore?.goalsFor || 0,
      goalsConceded: chapter.homeGoalsBefore?.goalsAgainst || 0,
    },
    club2: {
      name: chapter.away?.shortName || null,
      logoImageSrc: chapter.away?.teamIconUrl || null,
      logoImageAlt: chapter.away?.threeLetterCode || null,
      clubTotal: chapter.awayGoals,
      color: color2.background,
      textColor: color2.color,
      goalsScored: chapter.awayGoalsBefore?.goalsFor || 0,
      goalsConceded: chapter.awayGoalsBefore?.goalsAgainst || 0,
    },
    time: timeFormatter.format(kickoffTime),
    goals: chapter.hasResults
      ? {
          home: chapter.homeGoals || 0,
          away: chapter.awayGoals || 0,
        }
      : undefined,
  };
};

const Chapter4: React.FC = () => {
  const dispatch = useAppDispatch();
  const { matchId } = useParams();
  const chapter = useAppSelector((state) => state.chapters.goalsBefore.entities[matchId || 0]);

  useEffect(() => {
    (async () => {
      if (matchId) {
        await dispatch(fetchGoalsBeforeChapter(matchId));
      }
    })();
  }, [dispatch, matchId]);

  const chapterConfig = getChapterConfig(chapter);

  return <>{chapterConfig && <KickerChapter4Table config={chapterConfig} />}</>;
};

export default Chapter4;
