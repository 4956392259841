import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './Home/Home';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { kickerTheme } from '@webfox-sc/dataviz-poc';
import Chapters from './Chapters/Chapters';

const GlobalStyle = createGlobalStyle({
  body: {
    backgroundColor: '#f7f7f7',
    margin: 0,
  },
});

const App: React.FC = () => {
  return (
    <>
      <ThemeProvider theme={kickerTheme as any}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="match/:matchId/kapitel/:chapterId" element={<Chapters />} />
        </Routes>
      </ThemeProvider>
      <GlobalStyle />
    </>
  );
};

export default App;
