import React, { useEffect } from 'react';
import { KickerMatchDayButtonTable, KickerMatchDayTable, KickerMatchDayTableConfigProps } from '@webfox-sc/dataviz-poc';
import { UiDiv } from '@webfox-sc/core';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { fetchMatchDay, fetchMatchDays, setSelectedMatchDay } from '../../app/slices/matchDaysSlice';
import { sortBy } from 'lodash';
import { dateFormatterLong, timeFormatter } from '../../utils/date';

const getMatchDayTableConfig = (
  matchDay?: MatchDay,
  onClick?: (matchId: number) => void
): KickerMatchDayTableConfigProps | undefined => {
  if (!matchDay) {
    return undefined;
  }
  const config: KickerMatchDayTableConfigProps = {
    matchDay: `${matchDay.matchDay}. Spieltag`,
    rows: [],
  };

  const matches = sortBy(matchDay.matches || [], 'plannedKickoffTime');

  config.rows = Object.values(
    matches.reduce((result, match) => {
      const kickoffTime = new Date(match.plannedKickoffTime || '');
      const date = dateFormatterLong.format(kickoffTime);
      if (!result[date]) {
        result[date] = [{ line: true }, { date }];
      }

      result[date].push({
        club1: {
          name: match.home?.shortName || null,
          logoImageSrc: match.home?.teamIconUrl || null,
          logoImageAlt: match.home?.threeLetterCode || null,
        },
        club2: {
          name: match.away?.shortName || null,
          logoImageSrc: match.away?.teamIconUrl || null,
          logoImageAlt: match.away?.threeLetterCode || null,
        },
        time: timeFormatter.format(kickoffTime),
        goals: match.hasResults
          ? {
              home: match.homeGoals,
              away: match.awayGoals,
            }
          : undefined,
        onClick: () => onClick && onClick(match.id),
      });
      return result;
    }, {} as KickerMatchDayTableConfigProps['rows'])
  ).flat();

  return config;
};

const Home: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const selectedMatchDay = useAppSelector((state) => state.matchDays.selectedMatchDay) || 12;
  const matchDayIds = useAppSelector((state) => state.matchDays.matchDayIdsByMatchDayNr);
  const matchDayId = matchDayIds && matchDayIds[selectedMatchDay];
  const matchDay = useAppSelector((state) => state.matchDays.matchDays.entities[matchDayId || 0]);

  useEffect(() => {
    (async () => {
      if (!matchDayIds) {
        await dispatch(fetchMatchDays());
      }
    })();
  }, [dispatch, matchDayIds]);

  useEffect(() => {
    (async () => {
      if (!matchDay && matchDayId) {
        await dispatch(fetchMatchDay(matchDayId));
      }
    })();
  }, [dispatch, matchDay, matchDayId]);

  const matchDayTableConfig = getMatchDayTableConfig(matchDay, (matchId) => navigate(`/match/${matchId}/kapitel/1`));

  return (
    <>
      <UiDiv>{matchDayTableConfig && <KickerMatchDayTable key={matchDayId} config={matchDayTableConfig} />}</UiDiv>

      <UiDiv paddingTop="75px" paddingBottom="25px">
        <KickerMatchDayButtonTable
          matchDay={selectedMatchDay}
          title="Wähle einen Spieltag"
          onClick={(matchDayNr) => dispatch(setSelectedMatchDay(matchDayNr))}
        />
      </UiDiv>
    </>
  );
};

export default Home;
