import React, { useEffect } from 'react';
import { KickerChapter2Table, KickerChapter2TableConfigProps } from '@webfox-sc/dataviz-poc';
import { dateFormatterShort, timeFormatter } from '../../utils/date';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useParams } from 'react-router-dom';
import { fetchLeagueTableBeforeChapter } from '../../app/slices/chaptersSlice';
import { getColors } from '../../utils/color';

const getSeasonRank = (
  season: LeagueTableBeforeChapterSeason | undefined,
  teamId: number | undefined
): number | null => {
  if (!season || !teamId) {
    return null;
  }

  const index = season.leagueTable.findIndex((item) => item.team.id === teamId);
  if (index === -1) {
    return null;
  }

  return index + 1;
};

const getChapterConfig = (chapter?: LeagueTableBeforeChapter): KickerChapter2TableConfigProps | undefined => {
  if (!chapter) {
    return undefined;
  }

  const kickoffTime = new Date(chapter.plannedKickoffTime || '');

  const nrOfPastMatches = (chapter.matchday?.matchDay || 0) - Number(!chapter.hasResults);

  const [color1, color2] = getColors(chapter.home?.colorSets, chapter.away?.colorSets);

  return {
    date: dateFormatterShort.format(new Date(kickoffTime)),
    time: timeFormatter.format(kickoffTime),
    goals: chapter.hasResults
      ? {
          home: chapter.homeGoals || 0,
          away: chapter.awayGoals || 0,
        }
      : undefined,
    gameLabel: `nach ${nrOfPastMatches} Spielen`,
    ranks: 18,
    rankNumsWithBorderTop: [1, 5, 6, 7, 16, 17],
    lowerLeagueLabel: '2BL',
    club1: {
      name: chapter.home?.shortName || null,
      logoImageSrc: chapter.home?.teamIconUrl || null,
      logoImageAlt: chapter.home?.threeLetterCode || null,
      color: color1.background,
      textColor: color1.color,
      season1Rank: getSeasonRank(chapter.seasons?.[0], chapter.home?.id),
      season2Rank: getSeasonRank(chapter.seasons?.[1], chapter.home?.id),
    },
    club2: {
      name: chapter.away?.shortName || null,
      logoImageSrc: chapter.away?.teamIconUrl || null,
      logoImageAlt: chapter.away?.threeLetterCode || null,
      color: color2.background,
      textColor: color2.color,
      season1Rank: getSeasonRank(chapter.seasons?.[0], chapter.away?.id),
      season2Rank: getSeasonRank(chapter.seasons?.[1], chapter.away?.id),
    },
    season1: chapter.seasons?.[0].season || '',
    season2: chapter.seasons?.[1]?.season || '',
  };
};

const Chapter2: React.FC = () => {
  const dispatch = useAppDispatch();
  const { matchId } = useParams();
  const chapter = useAppSelector((state) => state.chapters.leagueTableBefore.entities[matchId || 0]);

  useEffect(() => {
    (async () => {
      if (matchId) {
        await dispatch(fetchLeagueTableBeforeChapter(matchId));
      }
    })();
  }, [dispatch, matchId]);

  const chapterConfig = getChapterConfig(chapter);

  return <>{chapterConfig && <KickerChapter2Table config={chapterConfig} />}</>;
};

export default Chapter2;
